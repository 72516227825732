@use "assets/styles" as *;

.wrapperLoader {
  height: 245px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 100%;
    height: 1px;
  }
}

.linkBtn {
  display: inline-flex;
}

.wrapperTable {
  width: 100%;
  overflow-x: auto;
}

.tableCalls {
  min-width: 1000px;
  overflow-x: auto;
  > thead {
    > tr {
      display: grid;
      grid-column-gap: 5px;
      text-align: left;
      grid-template-columns: 55px 1fr 0.3fr minmax(100px, 1.5fr) 0.5fr 1fr 0.5fr 0.5fr 1fr 1fr 30px;
      border-bottom: 1px solid $divider;
    }
  }

  > tbody {
    > tr {
      display: grid;
      grid-template-columns: 55px 1fr 0.3fr minmax(100px, 1.5fr) 0.5fr 1fr 0.5fr 0.5fr 1fr 1fr 30px;
      grid-column-gap: 5px;
      border-bottom: 1px solid $divider;
      min-height: 59px;
    }
  }
}

.tableNumbers {
  > thead {
    > tr {
      display: grid;
      grid-column-gap: 5px;
      text-align: left;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      border-bottom: 1px solid $divider;
    }
  }

  > tbody {
    > tr {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-column-gap: 5px;
      border-bottom: 1px solid $divider;
      min-height: 59px;
    }
  }
}

.titleTable {
  margin: 56px 0 30px 0;
  @include h3-bold;
}

.row {
  @include text-primary-semibold;
  color: $textSecondary;

  > td {
    padding: 0 0 0 3px;

    > div {
      display: flex;
    }
  }
}

.rowFlex {
  word-break: break-word;
  display: flex;
  align-items: center;
  height: 100%;
}

.block {
  display: inline-flex !important;
  justify-content: center;
  align-items: flex-start;
}

.switch {
  > span {
    margin-left: 0 !important;
  }
}

.black {
  @include text-primary-bold;
  color: $black;
}

.link {
  &:hover {
    background-color: #f8f8f8;
    cursor: pointer;
  }
}

.upper {
  text-transform: capitalize;
}

.more {
  cursor: pointer;

  > svg {
    font-size: 14px;
  }
}

.back {
  position: absolute;
  left: 24px;
}

.iconButton {
  padding: 4px !important;
  color: #2f3037 !important;
}

.sizeIcon {
  font-size: 21px !important;
}

.numberText {
  @include text-primary-regular;
  margin-right: 4px;
}

.txtPrimary {
  @include text-primary-regular;
}

.linkItem {
  display: inline-flex;
  margin-bottom: 26px;
}

.icon {
  width: 16px !important;
  height: 16px !important;
}

.wrapperInfo {
  padding-top: 32px;
}

.mainInfo {
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  .rowMain {
    display: flex;
    flex-direction: column;
  }
}

.label {
  text-transform: uppercase;
  color: $textSecondary;
  @include semi-bold;
  font-size: 12px;
  line-height: 26px;
  margin-bottom: 4px;

  &.empty {
    opacity: 0;
  }
}

.text {
  @include text-primary;
  display: flex;
  align-items: center;
}

.numberGrid {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, auto));
  grid-gap: 20px;
}

.error {
  color: #f44336;
}

.wrapperItemNumber {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.iconQuestion {
  color: #d8d8d8;
}

.wrapperNumber {
  display: flex;
  align-items: center;
}

.flag {
  min-width: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  margin-right: 10px;

  > img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.footer {
  display: flex;
  align-items: center;
  margin-left: -10px;
  margin-bottom: 42px;

  > button {
    font-size: 16px !important;
    margin-right: 10px;
  }

  > span {
    @include text-secondary;
    color: $textSecondary;
  }
}

.routeGroups {
  max-width: 600px;
  display: flex;
  flex-direction: column;
}

.routeWrapper {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: minmax(100px, 1fr) 1fr 1fr;

  > div {
    margin-right: 20px;
  }

  .nameRoute {
    text-transform: capitalize;
    @include text-primary-semibold;
  }
}

.tableCalls {
  margin-top: 0;
}

.titleTableCalls {
  margin-top: 70px;
  @include h3-bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;

  > button {
    margin-left: 10px;
  }
}

.errorCheckbox {
  > span {
    color: #f44336 !important;
  }
}

.tableWrap {
  margin-bottom: 21px;
}

.circle {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  border-radius: 50%;
  overflow: hidden;

  > div {
    width: 15px;
    height: 15px;
  }
}

.green {
  background-color: $green;
}

.red {
  background-color: red;
}

.yellow {
  background-color: $accent;
}

.blue {
  background-color: $primary;
}

.blackCircle {
  background-color: black;
}

.gray {
  background-color: $textSecondary;
}

.wrapperLoaderForm {
  height: calc(100vh - 300px);
}

.block {
  display: flex;
  flex-direction: column;

  > label {
    &:first-child {
      border-top: 1px solid #d8d8d8;
    }

    border-bottom: 1px solid #d8d8d8;
  }
}

.form {
  max-width: 875px;

  .formRow {
    display: flex;
    align-items: center;

    &.field {
      max-width: 600px;
    }
    > div {
      &:nth-child(2) {
        padding-left: 15px;
      }
    }

    .formLabel {
      @include text-label;
      @include semi-bold;
      text-transform: uppercase;
      color: $textSecondary;
      width: 140px;
      min-width: 140px;
      max-width: 140px;
    }
  }

  .gridRow {
    margin-top: 12px;
  }
}

.errorMargin {
  margin-left: 150px;

  > p {
    color: red !important;
  }
}

.numberField {
  width: 218px;
}

.fullWidth {
  width: 100%;
}

.flex {
  display: flex;
  align-items: center;
  gap: 12px;
}

.uploadBtn {
  background-color: #dbebff !important;

  > span {
    color: #0176fe !important;
  }
}

.number {
  align-items: flex-start !important;
}

.labelNumber {
  padding-top: 25px;
}

.or {
  @include text-secondary;
  @include semi-bold;
  color: $textSecondary;
}

.absolute {
  position: absolute;
}

.message {
  @include text-secondary-semibold;
  color: rgba(124, 124, 124, 0.4);
  margin-bottom: 12px;
}

.fieldWidth {
  width: 453px;
}

.date {
  display: flex;

  > div {
    &:nth-child(1) {
      margin-right: 10px;
    }
  }
}

.rowGrid {
  width: 100%;
  overflow-x: auto;
  display: grid;
  grid-template-columns: repeat(8, minmax(80px, 90px));
}

.input {
  opacity: 1 !important;

  > p {
    margin: -1px 0 12px 0 !important;
  }
}

.start {
  margin-bottom: 44px;
  display: flex;
  align-items: flex-start !important;

  .formLabel {
    padding-top: 20px !important;
  }
}

.days {
  grid-template-columns: repeat(7, minmax(80px, 90px));
}

.hoursMargin {
  margin-top: 30px;
}

.daysMargin {
  margin: 24px 0 52px 0 !important;
}

.wrapperLabelRoute {
  padding: 20px 5px 20px 0;
  display: grid;
  grid-template-columns: 150px 150px auto;

  .nameRoute {
    @include bold;
  }
}

.dateInput {
  > div {
    padding-right: 0 !important;
  }

  > p {
    margin: -1px 0 12px 0 !important;
  }
}

.wrapperPaper {
  padding: 0 !important;
}

.wrapperSchedule {
  padding: 20px 0 24px 0;
  border-bottom: 1px solid $divider;
}

.counters {
  @include h2-bold;
  span {
    cursor: pointer;
    &:first-child {
      margin-right: 46px;
    }
  }
}

.bar {
  display: flex;
  @include text-secondary;
  margin-bottom: 30px;
  .barItem {
    &:not(:first-child) {
      margin-left: 16px;
    }
    display: flex;
    align-items: center;
    .square {
      border-radius: 2px;
      width: 11px;
      height: 11px;
      margin-right: 4px;
    }
  }
}

.tooltip {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 10px 15px;
  color: $textPrimary;
  font-size: 12px;
}

.grey {
  color: $textSecondary;
}

.nowrap {
  white-space: nowrap;
}

.disabled {
  background-color: rgba(1, 118, 254, 0.5) !important;
}

.disabledSwitch {
  > span {
    &:last-child {
      background-color: rgba(1, 118, 254, 0.5) !important;
    }
  }
}

button.btnSecondary {
  background-color: #dbebff;

  &:hover {
    background-color: #d2e2f5;
  }

  &:disabled {
    background-color: rgba(219, 235, 255, 0.5);
  }
}

.mt24 {
  margin-top: 24px;
}

.mb56 {
  margin-bottom: 56px;
}

.listInCell {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  gap: 8px;

  > li {
    white-space: nowrap;
  }
}

div.networkSelect {
  min-width: 218px;
  width: fit-content;
}

.table {
  --padding-cell: 8px 8px;

  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-collapse: separate;

  tr {
    text-align: left;

    display: grid;
    gap: 8px;
    grid-auto-flow: column;
    // prettier-ignore
    grid-template-columns:
      //    SWITCH                  NAME                    STATUS                TIME SCOPE
            40px            minmax(100px, 0.5fr)    minmax(25px, 0.1fr)        minmax(200px, 1fr)
      // A-SIDE                    B-SIDE                  ROUTE GROUPS            MAX CALLS     CHEVRON ICON
           95px             minmax(100px, 0.5fr)               125px                 100px            30px;
    // prettier-ignore
  }

  th {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      flex-shrink: 0;
    }
  }

  td {
    @extend .fontPrimary;
    display: flex;
    align-items: flex-start;
    gap: 8px;

    svg {
      flex-shrink: 0;
    }

    overflow: hidden;
    text-overflow: ellipsis;

    & * {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  thead {
    border-bottom: 1px solid #d8d8d8;

    tr > th {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      color: #8c8c8c;
      padding: 12px 0;
    }
  }

  tbody.content {
    min-height: 50px;

    tr {
      @include transition();

      border-bottom: 1px solid #d8d8d8;

      &:hover {
        cursor: pointer;
        background: #f9f9f9;
      }

      td {
        padding: 16px 0;
        // white-space: nowrap;

        // &.disabled {
        //   color: var(--gray-i);
        // }
      }
    }

    .arrowCell {
      color: #b8c0d1;
    }

    .nameCell {
      font-weight: 700;
    }

    .ruleSwitch {
      margin: 0;
      margin-left: 6px;
    }
  }
}

.italic {
  font-style: italic;
}

.mb0 {
  margin-bottom: 0;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mt10 {
  margin-top: 10px;
}

.mt16 {
  margin-top: 16px;
}

.mt32 {
  margin-top: 32px;
}

.flex {
  display: flex;
  align-items: center;
}

.gap10 {
  gap: 10px;
}

.rowGap25 {
  row-gap: 25px;
}

.m0 {
  margin: 0;
}
