@use "assets/styles" as *;

.wrapper {
  position: relative;
  display: flex;
  background-color: $white;
  color: $textPrimary;
}

.wrapperDivider {
  background-color: $divider;
}

.content {
  padding-left: 281px;
  width: calc(100vw - 281px);
  min-height: 100vh;
  overflow-x: hidden;
}
