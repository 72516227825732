/*rest css (best practices 2021)*/

*::before,
*::after {
    box-sizing: border-box;
}

ul[class],
ol[class] {
    padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

body {
    font-family: 'Nunito Sans', sans-serif;
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

ul[class],
ol[class] {
    list-style: none;
}

a:not([class]) {
    text-decoration-skip-ink: auto;
}

a {
    text-decoration: none;
}

a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
}

img {
    max-width: 100%;
    display: block;
}

article > * + * {
    margin-top: 1em;
}

input,
button,
textarea,
select {
    font: inherit;
}

/*intercom style (need use !important (don't touch this))*/
.intercom-launcher, .intercom-launcher-frame {
    background-color: #FBA700 !important;
}

/* important because material ui added this params in body (delete this, if you know what are you doing)*/
body {
    padding: 0 !important;
    overflow: auto !important;
}

input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

#dashboardHeader {
    margin-bottom: -26px;
}

