@use "assets/styles" as *;

.wrapper {
  color: $textPrimary;
  margin-top: 42px;
}

.subtitle {
  @include h3-bold;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  >span {
    cursor: pointer;
    margin-left: 12px;
    display: flex;
    align-items: center;
    color: $primary;
  }
}

.wrapperForm {
  width: 450px;
}

.checkboxes {
  width: 500px;
  margin-bottom: 52px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.row {
  display: grid;
  grid-template-columns: 264px 181px;
  grid-column-gap: 9px;
}

.row {
  @include text-primary-semibold;
  color: $textPrimary;

  > td {
    white-space: nowrap;
    padding: 0;
    > div {
      display: flex;
    }
  }
}

.rowFlex {
  display: flex;
  align-items: center;
  height: 100%;
}

.table {
  max-width: 1000px;
  width: auto;
}

.tableNetwork {
  > thead {
    padding: 0;
    border-bottom: 1px solid $divider;
    > tr {
      display: grid;
      text-align: left;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 30px;
      grid-column-gap: 10px;
      padding-right: 20px;
    }
  }

  > tbody {
    > tr {
      padding: 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 30px;
      grid-column-gap: 10px;
      border-bottom: 1px solid $divider;
      min-height: 59px;
      padding-right: 20px;
    }
  }
}

.copyIcon {
  margin-left: 4px;
  cursor: pointer;
}

.disabled {
  color: $textSecondary;
}

.empty {
  margin: 0 48px;
  color: $textSecondary;
  @include text-secondary-regular;

  > div {
    margin-bottom: 21px;
  }
}
