@use "assets/styles" as *;

.wrapper {
  padding: 65px 45px 65px 65px;
  color: $textPrimary;

  .title {
    @include h2-regular;
    @include extra-bold;
  }

  .info {
    margin-top: 6px;
    @include text-primary-regular;

    > a {
      text-decoration: underline;
    }
  }

  .subtitle {
    margin: 39px 0 14px 0;
    @include h3-bold;
  }

  .infoBilling {
    @include text-primary-regular;
    margin-bottom: 5px;

    > a {
      color: $primary;
      text-decoration: underline;
    }
  }
}

.table {
  max-width: 1000px;
  width: auto;
}

.tableBilling {
  > thead {
    padding: 0;
    border-bottom: 1px solid $divider;
    > tr {
      display: grid;
      text-align: left;
      grid-template-columns: 100px 120px 1fr 1fr 1fr 1fr 1fr;
      grid-column-gap: 10px;
      padding-right: 20px;
    }
  }

  > tbody {
    > tr {
      padding: 0;
      display: grid;
      grid-template-columns: 100px 120px 1fr 1fr 1fr 1fr 1fr;
      grid-column-gap: 10px;
      border-bottom: 1px solid $divider;
      min-height: 59px;
      padding-right: 20px;
    }
  }
}

.row {
  @include text-primary-semibold;
  color: $textPrimary;

  > td {
    white-space: nowrap;
    padding: 0;
    > div {
      display: flex;
    }
  }
}

.rowFlex {
  display: flex;
  align-items: center;
  height: 100%;
}

.dash {
  text-decoration: line-through;
  color: $textSecondary;
}

.notDash {
  color: $textSecondary;
}

.fee {
  color: $textSecondary;
}

.uppercase {
  text-transform: uppercase;
}

.download {
  padding-right: 20px;
  cursor: pointer;
  text-decoration: underline;
}

.empty {
  grid-column: 1/7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapperLoader {
  position: relative;
  height: 150px;
}
