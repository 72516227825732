@use "assets/styles" as *;

.wrapper {
  overflow-x: hidden;
  margin-top: 61px;
  box-sizing: border-box;
  width: calc(100% - 230px);
  display: flex;
  padding: 0 59px;

  .wrapperForms {
    background-color: $white;
    min-height: 520px;
    max-width: 580px;
    width: 100%;
    border-radius: 5px;
    padding: 58px 69px 41px 60px;
    box-sizing: border-box;
  }
}

.general {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .wrapper {
    width: 100%;
  }
}


