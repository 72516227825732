@use "assets/styles" as *;

.wrapper {
  position: fixed;
  width: 281px;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;
  bottom: 0;
  left: 0;
  top: 0;
  background-color: #1B376E;
  color: $white;
  padding-bottom: 40px;
  box-sizing: border-box;
  box-shadow: -28px 0px 32px -23px rgba(0,0,0,0.2) inset;
}

.wrapper::-webkit-scrollbar {
  width: 3px;
}

.wrapper::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

.logoWrap {
  margin: 60px 53px 41px 41px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    width: 186px;
    height: 17.3px;
    background-image: url("../../../assets/icons/logo_white.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .logoText {
    position: relative;
    left: 3px;
    margin-top: 10px;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 11px;
    line-height: 15px;
    font-weight: 600;
  }
}

.navigation {
  padding: 0 12px;
  width: calc(100% - 26px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .navTitle {
    box-sizing: border-box;
    margin-left: 30px;
    margin-bottom: 4px;
    margin-top: 10px;
    font-weight: 400;
    font-size: 11px;
    line-height: 26px;
    color: rgba(255, 255, 255, 0.8);
    text-transform: uppercase;
  }

  .nav {
    width: 100%;
    display: flex;
    height: 37px;
    padding: 0 21px 0 30px;
    margin: 2px 0;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    @include text-secondary;
    @include semi-bold;
    &:hover {
      background-color: darken(#2C4D8D, 5);
      border-radius: 4px;
    }

    &.activeNav {
      background-color: #2C4D8D;
      border-radius: 4px;
    }

    > span {
      margin-left: 17px;
    }
    > svg {
      font-size: 17px !important;
    }
  }
}

.notFirst {
  margin-top: 8px;
}




