@use "assets/styles" as *;

.wrapper {
  width: 100%;
  user-select: none;
  cursor: pointer;
  height: 78px;
  padding: 25px 20px 9px 20px;
  box-sizing: border-box;
  border: 2px solid #D8D8D8;
  display: flex;
  align-items: flex-start;
  border-radius: 5px;

  &.active {
    border: 2px solid $primary;
  }

  &:hover {
    border: 2px solid $primary;
  }
}

.addWrapper {
  padding: 0;
  align-items: center;
  justify-content: center;
  color: $primary;
}
