@use "assets/styles" as *;

.wrapper {
  padding: 65px 45px 65px 65px;
  max-width: 490px;
  .title {
    @include h2-regular;
    @include extra-bold;
  }
  .info {
    margin-top: 6px;
    @include text-primary-regular;
  }
  .subtitle {
    margin: 35px 0 4px 0;
    @include h3-bold;
  }
  .details {
    margin: 42px 0 4px 0;
    @include h3-bold;
  }
  .halfWidth {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 12px;
  }
  .submit {
    margin-top: 23px;
  }
}
