@use "assets/styles" as *;

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 45px 0 59px;
  box-sizing: border-box;
  color: $white;

  .logo {
    margin-top: 58px;
    width: 216px;
  }

  .contentMenu {
    display: flex;
    text-transform: none;
    align-items: center;
    color: $white;

    .contentMenuText {
      font-weight: 600;
      font-size: 14px;
      margin: 0 12px;
    }
  }
}

.flex {
  display: flex;
  flex-direction: column;
}
