@use "assets/styles" as *;

.wrapperSchedule {
  margin-top: 45px;
  min-height: 245px;
  @include text-secondary-semibold;
}

.wrapperCounters {
  margin-bottom: 56px;
  display: flex;
  align-items: flex-end;
}

.info {
  margin-right: 62px;
  > .name {
    @include h2-extraBold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-width: 40vh;
    max-height: 88px;
    word-break: break-word;
    -webkit-line-clamp: 2 !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.counter {
  display: flex;
  @include h2-bold;
}

.flex {
  display: flex;
  align-items: center;
}

.mg {
  margin-left: 20px;
}

.label {
  font-size: 14px !important;
  line-height: 19px !important;
  font-weight: 600 !important;
  > span {
    font-size: 14px !important;
    line-height: 19px !important;
    font-weight: 600 !important;
  }
}

.point {
  padding: 0 !important;
  margin-left: 9px !important;
  border-radius: 2px;
  > span {
    > svg {
      width: 20px !important;
      height: 20px !important;
      margin-right: 5px;
    }
  }
}

.green {
  color: $green !important;
}

.white {
  color: $white !important;
}

.tooltip {
  background-color: rgba(255, 255,255 ,0.7);
  padding: 10px 15px;
  color: $textPrimary;
  font-size: 12px;
}
