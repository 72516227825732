@use "assets/styles" as *;

.wrapper {
  position: relative;
  background-color: $divider;
}

.contentWrapper {
  position: relative;
  padding: 55px 99px 100px 57px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 37px;
  .back {
    cursor: pointer;
    display: flex;
    color: $textSecondary;
    margin-right: 18px;
  }
  .headerContent {
    color: $textPrimary;
    .breadcrumbs {
      @include text-primary-semibold;
    }
    .title {
      @include h2-regular;
    }
  }
}

.disabled {
  pointer-events: none;
  cursor: default;
}

.active {
  cursor: pointer !important;
}

.companyInfo {
  max-width: 623px;
}

.content {
  background-color: $white;
  box-shadow: 0px 4px 5px rgba(135, 180, 206, 0.25);
  margin-left: calc(96px - 57px);
  padding: 38px 36px;
}

.stepLabel {
  cursor: pointer;
}

.label {
  margin-left: 10px;
  color: $textSecondary;
  @include text-primary-bold;
}

.labelActive {
  color: $textPrimary;
}

// material ui not functionality, when step not active (don't delete this) !
.completedContent {
  @include text-secondary-semibold;
  color: $textSecondary;
  word-break: break-word;
  margin-top: 8px;
  margin-bottom: -8px;
  border-left: 1px solid #bdbdbd;
  margin-left: 12px;
  padding-left: 20px;
  padding-right: 8px;
}
