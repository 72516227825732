@use "assets/styles" as *;

.wrapper {
  min-height: 100vh;
  background-color: $primary;
}

.loader {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

.wrapperPaper {
  padding: 0 !important;
}
