@use "assets/styles" as *;

.wrapper {
  padding: 65px 45px 65px 65px;
  .title {
    @include h2-regular;
    @include extra-bold;
  }
  .info {
    margin-top: 6px;
    @include text-primary-regular;
  }
  .subtitle {
    margin: 35px 0 4px 0;
    @include h3-bold;
  }
  .details {
    margin: 42px 0 4px 0;
    @include h3-bold;
  }
  .halfWidth {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 12px;
  }
  .submit {
    margin-top: 23px;
  }
}


.table {
  max-width: 936px;
  width: 936px;
}

.tableBilling {
  > thead {
    padding: 0 10px;
    border-bottom: 1px solid $divider;
    > tr {
      display: grid;
      text-align: left;
      grid-template-columns: 30% 1fr 1fr 1fr 1fr 1fr;
      grid-column-gap: 10px;
    }
  }

  > tbody {
    > tr {
      padding: 0 10px;
      display: grid;
      grid-template-columns: 30% 1fr 1fr 1fr 1fr 1fr;
      grid-column-gap: 10px;
      border-bottom: 1px solid $divider;
      min-height: 59px;
    }
  }
}

.row {
  @include text-primary-semibold;
  color: $textPrimary;

  > td {
    padding: 0 0 0 3px;
    > div {
      display: flex;
    }
  }
}

.rowFlex {
  display: flex;
  align-items: center;
  height: 100%;
}

.grey {
  color: $textSecondary;
}

.uppercase {
  text-transform: uppercase;
}

.download {
  cursor: pointer;
  text-decoration: underline;
}

.empty {
  grid-column: 1/7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapperTable {
  margin: 43px 0;
}

.loader {
  width: 936px;
  height: 286px;
}

.name {
  color: $textPrimary;
  @include bold;

  > svg {
    margin-right: 12px;
  }
}

.greyCircle {
  background-color: #ccc;
  width: 100%;
  height: 100%;
}

.flag {
  border-radius: 50%;
  margin-right: 14px;
  height: 19px;
  min-height: 19px;
  width: 19px;
  min-width: 19px;
  overflow: hidden;

  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.chip {
  @include text-secondary-regular;
  color: $white;
  padding: 4px 10px;
  border-radius: 5px;
}
