@use "assets/styles" as *;

.table {
  display: grid;
  .tableHead {
    text-transform: uppercase;
    color: $textSecondary;
    font-size: 12px;
    line-height: 26px;
    > tr {
      > th {
        @include regular;
      }
    }
  }

}
