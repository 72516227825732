@use "assets/styles" as *;

.wrapper {
  position: relative;
}

.content {
  position: relative;
  padding: 0 99px 100px 93px;
}

.header {
  position: relative;
  color: $white;

  .title {
    padding-top: 128px;
    @include h2-extraBold;
  }

  .text {
    @include text-primary-semibold;

    > span {
      cursor: pointer;
      @include text-primary-bold;
      text-decoration: underline;
    }
  }
}

.body {
  position: relative;
  margin-top: 50px;
  background-color: $white;
  width: 100%;
  min-height: 300px;
  box-shadow: 0 4px 30px rgba(135, 180, 206, 0.25);
  border-radius: 15px;
  padding: 0 0 50px 0;
  box-sizing: border-box;
}

.tableNodes {
  > thead {
    > tr {
      display: grid;
      text-align: left;
      grid-template-columns: 48px minmax(150px, 1.5fr) 1fr 1fr 1fr 1fr 60px;
      border-bottom: 1px solid $divider;
    }
  }

  > tbody {
    > tr {
      display: grid;
      grid-template-columns: 44px minmax(150px, 1.5fr) 1fr 1fr 1fr 1fr 60px;
      border-bottom: 1px solid $divider;
      min-height: 59px;
    }
  }
}

.tableNetworks {
  > thead {
    > tr {
      display: grid;
      grid-column-gap: 5px;
      text-align: left;
      grid-template-columns: 44px minmax(150px, 1.5fr) 1fr 1fr 1fr 1fr 60px;
      border-bottom: 1px solid $divider;
    }
  }

  > tbody {
    > tr {
      display: grid;
      grid-template-columns: 42px minmax(150px, 1.5fr) 1fr 1fr 1fr 1fr 60px;
      grid-column-gap: 5px;
      border-bottom: 1px solid $divider;
      min-height: 59px;
    }
  }
}

.row {
  @include text-primary-semibold;
  color: $textSecondary;

  > td {
    padding: 0 0 0 3px;
    > div {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.rowFlex {
  display: flex;
  align-items: center;
  height: 100%;
}

.name {
  color: $textPrimary;
  @include bold;
  flex-wrap: nowrap !important;

  > svg {
    margin-right: 12px;
  }
}

.status {
  width: 15px;
  height: 15px;
  margin-right: 7px;
  border-radius: 50%;
}

.black {
  color: $textPrimary;
}

.more {
  cursor: pointer;

  > svg {
    font-size: 14px;
  }
}

.flag {
  border-radius: 50%;
  margin-right: 14px;
  height: 19px;
  width: 19px;
  min-width: 19px;
  min-height: 19px;
  overflow: hidden;

  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.titleTable {
  padding: 37px 48px;
  display: flex;
  align-items: center;
  color: $textPrimary;
  @include extra-bold;
  font-size: 22px;
  line-height: 30px;

  > svg {
    cursor: pointer;
    margin-left: 15px;
    color: $primary;

    &:hover {
      color: #0057c0;
    }
  }
}

.empty {
  margin: 0 48px;
  color: $textSecondary;
  @include text-secondary-regular;

  > div {
    margin-bottom: 21px;
  }
}

.buttonContent {
  display: flex;
  align-items: center;

  > span {
    margin-left: 12px;
  }
}

.bg {
  height: 460px;
  position: absolute;
  top: -74px;
  right: 0;
  left: 0;
  background-color: $primary;
}

.footerTable {
  margin: 21px 48px 0;
  @include text-secondary-regular;
  color: $textSecondary;

  > a {
    text-decoration: underline;
  }
}

@media screen and (max-width: 1150px) {
  .content {
    padding: 0 40px 100px 40px;
    transition: padding-left ease-in-out 1s;
  }
}

.wrapperLoader {
  margin-top: 45px;
  height: 245px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  >div {
    width: 100%;
    height: 1px;
  }
}

.chip {
  @include text-secondary-regular;
  color: $white;
  padding: 4px 10px;
  border-radius: 5px;
}

.link {
  &:hover {
    background-color: #f8f8f8;
    cursor: pointer;
  }
}
