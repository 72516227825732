@use "assets/styles" as *;

.wrapper {
  max-width: 230px;
  display: flex;
  flex-direction: column;
  color: $white;

  .navLink {
    @include text-primary-bold;
    display: flex;
    align-items: center;
    white-space: nowrap;

    .dotContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15px;
      height: 15px;

      .dot {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background-color: $white;
      }
    }

    .text {
      margin-left: 15px;
    }
  }

  .active {
    opacity: 1;

    .dotContainer {
      .dot {
        width: 15px;
        height: 15px;
      }
    }
  }

  .title {
    white-space: nowrap;
    margin-bottom: 6px;
    font-weight: 900;
    font-size: 20px;
  }

  .subtitle {
    @include text-secondary-regular;
    margin-bottom: 35px;
  }

  .lineContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    margin: 10px 0;

    .line {
      width: 1px;
      height: 30px;
      background-color: $white;
    }
  }
}

.wrapperLinks {
  opacity: 0.5;
}

.activeWrapperLinks {
  opacity: 1;
}

@media screen and (max-width: 1200px) {
  .wrapper {
    margin-right: 59px;
  }
}

.disable {
  pointer-events: none;
}

.disableHasCursor {
  cursor: pointer;

  &:after {
    content: '';
    pointer-events: none;
  }
}
