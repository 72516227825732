@use "assets/styles" as *;

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .title {
    @include h2-extraBold;
  }

  .createSubtitle {
    @include text-secondary-regular;
    font-style: normal;
    margin: 18px -1px 30px;

    > a {
      @include semi-bold;
      color: $primary;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .loginSubtitle {
    margin-bottom: 22px;
  }
}

.formOperator {
  margin-top: 3px;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 9px;
}

.footer {
  margin-top: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .footerInfo {
    @include regular;
    margin: 0;
    max-width: 225px;
  }
}

.dialogButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapperLoaderIntegration {
  height: 68px;
}

.confirmEmail {
  @include text-primary-bold;
  margin-top: -20px;
  margin-bottom: 225px;
}

.wrapperButton {
  display: flex;
  justify-content: flex-end;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonsBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.operator {
  margin-bottom: 47px;
}

.more {
  @include text-secondary-regular;
  padding-top: 9px;
  min-height: 191px;
  max-height: 191px;
  color: $textSecondary;
}

.companyInfo {
  margin-top: 19px;
}

.wrapperActivation {
  margin: 50px 0 80px;
  @include text-primary-regular;

  > span {
    font-weight: 700;
  }
}

.margin {
  height: 26px;
}

.activationRow {
  padding: 24px 0;

  .name {
    @include text-primary-bold;
  }

  .text {
    @include text-primary-regular;
  }
}

.activationRow + .buttonsActivation {
  margin-top: 287px;
}

.activationRow + .activationRow + .buttonsActivation {
  margin-top: 213px;
}

.activationRow + .activationRow + .activationRow + .buttonsActivation {
  margin-top: 139px;
}

.activationRow
  + .activationRow
  + .activationRow
  + .activationRow
  + .buttonsActivation {
  margin-top: 44px;
}

.activationRow + .activationRow {
  padding-top: 0;
}

.subtitle {
  color: $textSecondary;
  font-weight: 400;
  font-size: 14px;
}

.loader {
  height: 286px;
}

.empty {
  margin-top: 42px;
}

.hasData {
  margin-top: 136px;
}

.other {
  margin-top: 47px;
}

.footerForgot {
  margin-top: 252px;
}

.errorHasOperator {
  font-size: 0.75rem;
  margin: 0 14px;
  color: rgba(0, 0, 0, 0.54);
  margin-top: -9px;
}

.form {
  margin-left: 10px;
}

.nameField {
  margin-top: 13px;
  width: 330px;
}

.formNetworkRow {
  display: grid;
  grid-template-columns: 1fr 1fr 150px;
  grid-column-gap: 12px;
  align-items: center;
}

.buttonAdd {
  height: 40px !important;
}

.footer {
  margin: 26px 0 0 1px;
}

.rowNetwork {
  margin-left: 10px;
  display: grid;
  align-items: center;
  grid-template-columns: 0.5fr 0.5fr 0.5fr 1fr;

  .nameNetwork {
    display: flex;
    align-items: center;

    .country {
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background-color: red;
      margin-right: 14px;
    }

    .text {
      @include text-primary-bold;
      color: $textPrimary;
    }
  }

  .codeNetwork {
    @include text-primary-semibold;
    color: $textSecondary;
  }

  .prefixNetwork {
    @include text-primary-semibold;
    color: $textSecondary;
  }

  .deleteNetwork {
    justify-content: flex-end;
    display: flex;

    .delete {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary;

      > svg {
        font-size: 1rem !important;
        margin-right: 12px;
      }
    }
  }
}

.createIntegration {
  max-width: 450px;
}

.integrationContent {
  max-width: 479px;
  margin: 6px 0 24px;
  color: $textSecondary;
  @include text-secondary-regular;
}

.csrRow {
  display: grid;
  grid-template-columns: 254px 216px;
  grid-column-gap: 14px;
}

.csrRowAll {
  display: grid;
  grid-template-columns: 484px;
}

.footerText {
  color: $textSecondary;
  @include text-secondary-regular;
}

.systemSelect {
  max-width: 344px;
}

.installTitle {
  @include text-primary-semibold;
  color: $textPrimary;
}

.copyIcon {
  cursor: pointer;
}

.scriptCopy {
  max-width: 781px;
}

.buttonInstall {
  display: flex;
  align-items: center;
}

.wrapperProgress {
  display: flex;
  margin-right: 8px;
  > div {
    width: 16px !important;
    height: 16px !important;
  }
}
