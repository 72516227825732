@use "assets/styles" as *;

.wrapper {
  padding: 65px 45px 65px 65px;
  .title {
    @include h2-regular;
    @include extra-bold;
  }
  .info {
    margin-top: 6px;
    @include text-primary-regular;
  }
  .subtitle {
    margin: 35px 0 4px 0;
    @include h3-bold;
  }
  .details {
    margin: 42px 0 4px 0;
    @include h3-bold;
  }
  .halfWidth {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 12px;
  }
  .submit {
    margin-top: 23px;
  }
}

.wrapperDetail {
  padding: 29px 0;
}

.wrapperItems {
  display: grid;
  grid-template-columns: repeat(auto-fill, 240px);
  grid-column-gap: 23px;
  grid-row-gap: 20px;
}

.wrapperLoader {
  height: 78px;
  width: 100%;
}

.wrapperLoaderLinear {
  margin-top: 42px;
  width: 100%;
}

.country {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  border-radius: 50%;
  min-width: 25px;
  min-height: 25px;
  overflow: hidden;

  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.content {
  .name {
    @include text-primary-bold;
    line-height: 22px;
    color: $textPrimary;
    width: 165px;
    display: block;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .infoItem {
    @include text-secondary-semibold;
    color: $textSecondary;
    width: 165px;
    padding-bottom: 3px;
    display: block;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.txt {
  @include text-primary-bold;
  margin-left: 9px;
  line-height: 22px;
}
