@mixin h1() {
  font-size: 36px;
  line-height: 55px;
}

@mixin h2() {
  font-size: 32px;
  line-height: 40px;
}

@mixin h3() {
  font-size: 22px;
  line-height: 30px;
}

@mixin text-primary() {
  font-size: 14px;
  line-height: 21px;
}

@mixin text-label() {
  font-size: 11px;
  line-height: 16px;
}

@mixin text-secondary() {
  font-size: 14px;
  line-height: 17px;
}

@mixin regular() {
  font-weight: 400;
}

@mixin semi-bold() {
  font-weight: 600;
}

@mixin bold() {
  font-weight: 700;
}

@mixin extra-bold() {
  font-weight: 900;
}

@mixin h1-regular() {
  @include h1;
  @include regular;
}

@mixin h1-bold() {
  @include h1;
  @include bold;
}

@mixin h2-regular() {
  @include h2;
  @include regular;
}

@mixin h2-bold() {
  @include h2;
  @include bold;
}

@mixin h2-extraBold() {
  @include h2;
  @include extra-bold;
}

@mixin h3-bold() {
  @include h3;
  @include extra-bold;
}

@mixin text-primary-regular() {
  @include text-primary;
  @include regular;
}

@mixin text-primary-semibold() {
  @include text-primary;
  @include semi-bold;
}

@mixin text-primary-bold() {
  @include text-primary;
  @include bold;
}

@mixin text-secondary-regular() {
  @include text-secondary;
  @include regular;
}

@mixin text-secondary-semibold() {
  @include text-secondary;
  @include semi-bold;
}

@mixin text-secondary-bold() {
  @include text-secondary;
  @include bold;
}
