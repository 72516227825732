@use "assets/styles" as *;

.wrapper {
  padding: 65px 45px 65px 65px;

  .title {
    @include h2-regular;
    @include extra-bold;
  }

  .info {
    margin-top: 6px;
    @include text-primary-regular;
  }

  .wrapperTable {
    margin-top: 40px;
  }

  .subtitle {
    @include h3-bold;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    >span {
      cursor: pointer;
      margin-left: 12px;
      display: flex;
      align-items: center;
      color: $primary;
    }
  }

  .details {
    margin: 42px 0 4px 0;
    @include h3-bold;
  }

  .halfWidth {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 12px;
  }

  .submit {
    margin-top: 23px;
  }
}

.link {
  margin: 0 3px;
  color: $primary;
  text-decoration: underline;

  &:hover {
    color: $primary;
    text-decoration: underline;
  }

  &:active {
    color: $primary;
    text-decoration: underline;
  }
}

.wrapperDetail {
  padding: 29px 0;
}

.wrapperItems {
  display: grid;
  grid-template-columns: repeat(auto-fill, 240px);
  grid-column-gap: 23px;
  grid-row-gap: 20px;
}

.wrapperLoader {
  height: 78px;
  width: 100%;
}

.wrapperLoaderLinear {
  margin-top: 42px;
  width: 100%;
}

.icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  border-radius: 50%;
  min-width: 25px;
  min-height: 25px;
  overflow: hidden;

  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.content {
  .name {
    @include text-primary-bold;
    line-height: 22px;
    color: $textPrimary;
    width: 165px;
    display: block;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .infoItem {
    @include text-secondary-semibold;
    color: $textSecondary;
    width: 165px;
    display: block;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.txt {
  @include text-primary-bold;
  margin-left: 9px;
  line-height: 22px;
}

.table {
  max-width: 1000px;
  width: auto;
}

.tableGeneral {
  > thead {
    padding: 0;
    > tr {
      display: grid;
      text-align: left;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-column-gap: 10px;
      padding-right: 20px;
    }
  }

  > tbody {
    > tr {
      padding: 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-column-gap: 10px;
      min-height: 59px;
      padding-right: 20px;
    }
  }
}

.tableNetwork {
  > thead {
    padding: 0;
    border-bottom: 1px solid $divider;
    > tr {
      display: grid;
      text-align: left;
      grid-template-columns: 1fr 1fr 1fr 1fr 30px;
      grid-column-gap: 10px;
      padding-right: 20px;
    }
  }

  > tbody {
    > tr {
      padding: 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 30px;
      grid-column-gap: 10px;
      border-bottom: 1px solid $divider;
      min-height: 59px;
      padding-right: 20px;
    }
  }
}

.tableTokens {
  > thead {
    padding: 0;
    border-bottom: 1px solid $divider;
    > tr {
      display: grid;
      text-align: left;
      grid-template-columns: 3fr 1fr 30px;
      grid-column-gap: 10px;
      padding-right: 20px;
    }
  }

  > tbody {
    > tr {
      padding: 0;
      display: grid;
      grid-template-columns: 3fr 1fr 30px;
      grid-column-gap: 10px;
      border-bottom: 1px solid $divider;
      min-height: 59px;
      padding-right: 20px;
    }
  }
}

.empty {
  margin: 0 48px;
  color: $textSecondary;
  @include text-secondary-regular;

  > div {
    margin-bottom: 21px;
  }
}

.row {
  display: grid;
  grid-template-columns: 264px 181px;
  grid-column-gap: 9px;
}

.row {
  @include text-primary-semibold;
  color: $textPrimary;

  > td {
    white-space: nowrap;
    padding: 0;
    > div {
      display: flex;
    }
  }
}

.rowFlex {
  display: flex;
  align-items: center;
  height: 100%;
}

.more {
  cursor: pointer;

  > svg {
    font-size: 14px;
  }
}

.chip {
  @include text-secondary-regular;
  color: $white;
  padding: 4px 10px;
  border-radius: 5px;
}
