.button {
  position: relative;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.inherit {
  color: #ffffff;
}

.transparent {
  color: transparent !important;
}

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
