@use "assets/styles" as *;

.wrapper {
  position: fixed;
  top: 24px;
  right: 38px;
  color: $white;
  display: flex;
  justify-content: space-between;
  z-index: 1;

  .item {
    display: flex;
    align-items: center;

    .icon {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-left: 16px;

      > svg {
        font-size: 36px;
      }

      &.primary {
        color: $white;
      }

      &.secondary {
        color: $primary;
      }
    }
  }
}

.select {
  > svg {
    color: #fff;
    padding-left: 14px;
    margin-right: 14px;
  }
}

.menuItem {
  @include text-secondary-regular;
  min-height: 50px;
}

.noHover {
  background-color: $white !important;

  &:hover {
    background-color: $white !important;
  }
}

.modal {
  position: absolute;
  width: 400px;
  background-color: $white;
  padding: 20px 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-box-shadow: 0px 0px 19px -6px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0px 0px 19px -6px rgba(0, 0, 0, 0.29);
  box-shadow: 0px 0px 19px -6px rgba(0, 0, 0, 0.29);
  outline: none !important;

  .titleModal {
    margin-top: 50px;
    font-size: 20px;
  }

  .buttonBar {
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
  }
}
