@use "assets/styles" as *;

.wrapper {
  position: relative;
  padding: 65px 45px 65px 65px;

  .title {
    @include h2-regular;
    @include extra-bold;
  }

  .info {
    margin: 6px 0 33px 0;
    @include text-primary-regular;
  }
}
